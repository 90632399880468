// Default SortableJS
import Sortable from 'sortablejs';
import FontName from 'fontname';
import Choices from 'choices.js';

// $(document).on('ready', function() {
$(document).ready(function() {
    console.log('Dashboard.js loaded...');
    
    var FILE_UPLOAD = {
        init: function(){
            $(document).on('change', 'input[type="file"]', function(){
                FILE_UPLOAD.change(this);
            });
        },
        change: function(input){
            let files = input.files;
                        
            if(files){
                $.each(files, function (index, file) {
                    var reader = new FileReader();
                    
                    reader.onload = function(e) {
                        let $box_img_dropzone = $(input).parents('.image-manage-box').find('.box_img_dropzone'),
                            image_label = $(input).parents('.image-manage-box').data('image-label'),
                            forLabel = $(input).parents('.image-manage-box').find('.files label').attr('for');
                            
                        if($box_img_dropzone.length){
                            $box_img_dropzone.remove();
                        }
                                             
                        $(` <div class="box_img_dropzone">
                                <label class="form-control-label d-block" for="${forLabel}">${image_label}</label>
                                
                                <div class="box-image">
                                    <img src="${e.target.result}" />
                                </div>

                                <div class="d-flex gap-5 dropzone-image-buttons">
                                    <div class="photo-delete photo-delete-prov">
                                        <div class="btn btn-danger mt-2">
                                            <i class="fa fa-times" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div class="photo-edit"><label class="btn btn-success mt-2" for="${forLabel}"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></label></div>
                                </div>
                            </div>`).insertBefore($(input).parents('.files'));

                        if($(input).parents('.image-manage-box').find('.photo-delete-hide').length){
                            $(input).parents('.image-manage-box').find('.photo-delete-prov').html($(input).parents('.image-manage-box').find('.photo-delete-hide'));
                            $(input).parents('.image-manage-box').find('.photo-delete-hide.d-none').removeClass('photo-delete-hide d-none');
                        }else{
                            if($(input).parents('.image-manage-box').find('.photo-delete-prov').length){
                                $(input).parents('.image-manage-box').find('.photo-delete-prov').on('click', function(){
                                    $(this).parents('.box_img_dropzone').remove();
                                    $(input).val('')
                                });
                            }
                        }
                    }
                    reader.readAsDataURL(file);
                });
            };
        }
    }
    
    function init(){
        FILE_UPLOAD.init();
    }

    init();

    if($('#model_brand_id').length){
        const selectBrand = document.querySelector('#model_brand_id');
        selectBrand.addEventListener('change', function(event){
            let action = $(this).parents('form').attr('action');

            $.ajax({
                url: action,
                type: "GET",
                data: {device_brand_id: event.target.value},
                success: function (html) {},
                error: function () {}
            });
        });
    }
    
    if($('.devices-config__item__icon .arrow-icon').length){
        $(document).on('click', '.devices-config__item__icon .arrow-icon', function(){
            let $parent_arrow = $(this).parents('.devices-config__item__icon'),
                $name_dropdown = $parent_arrow.next('.devices-config__item__name').find('.devices-config-brand__dropdown');
                
            $name_dropdown.slideToggle();
            $(this).toggleClass('opened');
        });
    }
    
    // var sortable = Sortable.create(el);
    // Sortable.create(el);
    if($('#sortable').length){
        var el = document.getElementById('sortable');
        
        var sortable = new Sortable(el, {
            handle: ".my-handle",
            onSort: function(evt) {
                evt.newIndex
    
                jQuery('#sortable .ui-state-default').each(function(ix){
                    if(jQuery(this).find('input#account_device_brands_order').length){
                        jQuery(this).find('input#account_device_brands_order').val(ix)
                    }
                });
    
            }
        });
    }
    
    if($('.font_file_input').length){
        $(document).on('change', '.font_file_input', function(){

            let files = this.files;
    
            if(files && $('.new-fonts-lib-ls').length){
                $('.new-fonts-lib-ls').html('');
    
                $.each(files, function (index, file) {
    
                    const fontFile = file; // File
                    const reader = new FileReader();
                    reader.onload = e => {
                        const buffer = e.target.result; // ArrayBuffer
                        try {
                            const fontMeta = FontName.parse(e.target.result)[0];
                            console.log(fontMeta);
                            if(fontMeta.fullName){
                                $('.new-fonts-lib-ls').append(`<li class="font-lib-item" style="font-family: '${fontMeta.fontFamily}';"><strong>${fontMeta.fullName}</strong></li>`)
    
                                if($('#font_full_name').length && fontMeta.fullName){
                                    $('#font_full_name').val(fontMeta.fullName);
                                }
    
                                if($('#font_family').length && fontMeta.fontFamily){
                                    $('#font_family').val(fontMeta.fontFamily);
                                }
                                if($('#font_sub_family').length && fontMeta.fontSubfamily){
                                    $('#font_sub_family').val(fontMeta.fontSubfamily);
                                }
                                if($('#font_post_script_name').length && fontMeta.postScriptName){
                                    $('#font_post_script_name').val(fontMeta.postScriptName);
                                }
                                if($('#font_typo_family_name').length && fontMeta.typoFamilyName){
                                    $('#font_typo_family_name').val(fontMeta.typoFamilyName);
                                }
                                if($('#font_typo_sub_family_name').length && fontMeta.typoSubfamilyName){
                                    $('#font_typo_sub_family_name').val(fontMeta.typoSubfamilyName);
                                }
                                if($('#font_url_designer').length && fontMeta.urlDesigner){
                                    $('#font_url_designer').val(fontMeta.urlDesigner);
                                }
                                if($('#font_url_vendor').length && fontMeta.urlVendor){
                                    $('#font_url_vendor').val(fontMeta.urlVendor);
                                }
                                if($('#font_version').length && fontMeta.version){
                                    $('#font_version').val(fontMeta.version);
                                }
                            }
                            // console.log(file)
                        } catch (e) {
                            // FontName may throw an Error
                        }
                    };
                    reader.readAsArrayBuffer(fontFile);
                });
    
                if($('.submit-new-fonts').length){
                    $('.submit-new-fonts').removeAttr('disabled');
                }
    
            }else{
                console.log('Sem arquivos para upload...');
            }
        });
    };
        
    if($('#choices-multiple-remove-button').length){
        var multipleCancelButton = new Choices('#choices-multiple-remove-button',
            {
                allowHTML: true,
                removeItemButton: true,
                loadingText: 'Carregando...',
                noResultsText: 'Nenhum resultado encontrado',
                noChoicesText: 'Sem opções para escolher',
                itemSelectText: 'Pressione para selecionar',
                uniqueItemText: 'Somente valores exclusivos podem ser adicionados',
                customAddItemText: 'Somente valores correspondentes a condições específicas podem ser adicionados',
                addItemText: (value) => {
                return `Pressione Enter para adicionar <b>"${value}"</b>`;
                },
                maxItemText: (maxItemCount) => {
                return `Somente ${maxItemCount} valores podem ser adicionados`;
                },
                valueComparer: (value1, value2) => {
                return value1 === value2;
                }
            }
        );
    };
    
          
});
