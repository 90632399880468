import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ReactToastify() {
    const showToastMessage = () => {
        // toast.success('Success Notification!', {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        
        if($('.notification__item').length){
          $('.notification__item').each(function(){
            let notificationType = $(this).data('type');
            if(notificationType == 'error'){
              toast.error($(this).text(), {
                position: toast.POSITION.TOP_RIGHT
              });
            }else if(notificationType == 'success'){
              toast.success($(this).text(), {
                position: toast.POSITION.TOP_RIGHT
              });
            }
          });
        }

    };
    return (
      <div>
        {showToastMessage()}
        <ToastContainer />
      </div>
    );
}
export default ReactToastify;